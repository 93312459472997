import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";
import styles from "../styles/refunds.module.scss";

const Refunds = () => {
  return (
    <Layout>
      <Container className="p-5">
        <h1 className={`${styles.title} mt-5 display-3 mb-5`}>
          Refund <span>Policy</span>
        </h1>
        <p>
          Products once purchased cannot be returned or refunded. In case of a
          defective product under warranty terms, the same product will be
          replaced. In case of any issue, we recommend reaching out to us on our
          hotline to discuss the details before returning the item.
        </p>
        <div className="mt-4 text-center">
          <Link to="/contact ">
            <Button size="lg">Contact Us</Button>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default Refunds;
